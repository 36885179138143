export const environment = {
  Wp: false,
  hasCustomizer:false,
  production: true,
  apiUrl: 'https://www.evolutionboutique.cloud/api',
  bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
  langPrefix: './assets/i18n/',
  langSuffix: '_Progetti.json',
  loginbgPath: 'assets/images/background/teamwork-3213924.jpeg',
  icon:'assets/images/KlimaIMGxicon.png',
  baseUrl: '/progetti/prodotti/home/lista',
  href: '/Login',
  homepages: [
    {Ruoli: ["C"], link: "/Cliente/profile"}
  ],
  logoPath: 'assets/images/logo_evolution.png',
  loginMsg: '',
  logoBg: 'skin6', // six possible values: skin(1/2/3/4/5/6),
  navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "",
  theme: 'light',
  dir: 'ltr', // two possible values: ltr, rtl
  layout: 'vertical', // fixed value. shouldn't be changed.
  sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'fixed', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed
  ClientiType: ['C', 'F'],
  IDTecnico: "RT",
  //#region Moduli
  Modules:{
    "twoFactorAuth": false,
    "Admin": true,
    "Personale": true,
    "Clienti": true,
    "Interventi": false,
    "Prima_Nota": false,
    "Listini": false,
    "Progetti": true,
    "Prodotti": true,
    "Documenti": false,
    "Scadenzario": false,
    "Spedizioni": false,
    "Stampe": false,
    "Task": false,
    "Shared": true,
    "Dashboard": false,
    "Settings": true,
    "Formazione": false,
    "VersioneCarbotti": false,
    "PartiProdotto": false,
    "Parti": false,
    "Rma": false,
    "Ecommerce": false,
    "ScadenzeFiglie": false,
    "Registrazione": false,
    "Ticket": false,
    "Acquisti": false,
    "taskboard": false,
    "Camera": false,
    "Valutazioni":false
  },
  //#endregion
  //#region Addon
  TaskAddon: {
    checkList : true,
  },
  Personale:{
    Corsi: true,
    Attivita: true,
    File: true,
  },
  ClientiAddOn: {
    codAdhoc:false,
    enable:false,
    info:false,
    commerciale: false,
    segnalatore: false,
    segnalatoreInterno: false,
    sedi: true,
    TipoSedePrimaria: false,
    attivita: false,
    geolocalizzazione: false,
    ruolo: false,
    Modifica: false,
    prodotti: false,
    rma: false,
    accesso: false,
    referenti: false,
    interventi: false,
    note_private:false,
    rating : false,
    ruoliAccesso:false,
    compilazione:false,
    luogoNascita: false,
    dataNascita: false,
    statoCivile:false,
    titoloStudio: false,
    occupazione: false,
    invalidita: false,
    legge104: false,
    diagnosi: false,
    accompagnamento:false,
    pensione: false,
    tutelare: false,
    medico: false,
    telMedico: false,
    terapia:false
  },
  ProdottiAddOn: {
    HideAggiungi: true,
    modificasolocategorie: true,
    sottoTitolo: false,
    shortDescription: false,
    classeEnergetica: false,
    tags: false,
    video: false,
    dettaglio:false,
    files: false,
    cart: false,
    codice: false,
    codice_produttore: false,
    tipo: false,
    standby: false,
    tipi:false, // tipi di prodotto (1 - 2ante klima)
    cat_image: true,
    lista_prezzo:false,
    TranslatedDesc: false,
    codAdhoc:false,
    DragSort: false,
    cat_Sort: true,
    CampiAggiuntivi: [
      {
        oggetti: ['sottoTitolo'],
        modificabile: false,
        nome: 'Stagione',
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
    ],
  },
  legenda:[],
  filtrife: [

  ],
  CalendarAddon: {
    managerScadenza : true,
  },
  PartiAddOn: [false],//  categorie associate
  RmaAddOn: {
    taskGenerico: true,
    vociDiCosto: false,
    provaAcquisto:true,
    CategoriaProdotto: true,
    TipoAcquisto: false,
    TipoAcquistoSource: "ClientiType",
    Rivenditore:false,
    NumeroDocumento: false,
    DataDocumento: false,
    Ricevuta: false,
    Targhetta: false,
    CircostanzaDifetto: false,
    DescrizioneDifetto: false,
    Difetto: true,
    Telefono: true,
    Garanzia: true,
    EstensioneGaranzia: true,
    Allegati: true,
    Modello: true,
    Matricola: true,
    Brand: true,
    sottoTask: true,
    Ritiro: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Cliente: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Assistenza: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      Tecnico: false,
    }
  },
  ProgettiAddon: {
    commerciale: false,
    segnalatore: false,
    tracciato: true,
    categoriaVoceDiCosto: false,
    lavorato: false,
    HidePrezzo:true,
    CampiOfferta: [
      {
        modificabile: true,
        nome: 'Oggetto:',
        oggetti: ['oggetto'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-2',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'N. Ordine:',
        oggetti: ['codice'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-1',
        placeholder: 'Numero Ordine:',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Creazione',
        oggetti: ['data_creazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Offerta',
        oggetti: ['data_emissione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note Private',
        oggetti: ['note_private'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-12',
        visibleifProhibited: false
      },
    ],
    CampiSottoOfferta: []
  },
  //#endregion
  //#region Menu
  Menu: [
    {
      title: "Menu",
      class: "has-arrow",
      label: "Menu",
      extralink: true
    },
    {
      module: "Dashboard",
      path: "dashboard",
      title: 'menu.dashboard',
      icon: "mdi mdi-gauge",
      capacita: ["menu-dashboard"]
    },
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    // {
    //   module: "Clienti",
    //   capacita: ["menu-clienti"],
    //   path: "/listautenti/aziende/c",
    //   title: 'Clienti',
    //   icon: "mdi mdi-account-multiple",
    // },
    // {
    //   module: "Clienti",
    //   capacita: ["menu-clienti"],
    //   path: "/listautenti/aziende/f",
    //   title: 'Fornitori',
    //   icon: "mdi mdi-account-multiple",
    // },
    {
      module: "Clienti",
      path: "/listautenti/aziende",
      title:'menu.clienti',
      icon: "mdi mdi-account-multiple",
      capacita: ["menu-clienti"]
    },
    {
      module:"Progetti",
      path: "progetti/progetti",
      title: 'Ordini',
      icon: "mdi mdi-calendar-multiple-check",
      capacita: ["menu-progetti"]
    },
    {
      module: "Task",
      path: "task/unita",
      title: 'Calendario',
      icon: "mdi mdi-calendar",
    },
    {
      module: "taskboard",
      path: "task/attivita",
      title: 'menu.taskboard',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Listini",
      path: "listino/listini",
      title: 'menu.listini',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Acquisti",
      path: "acquisti/acquisti",
      title: 'menu.acquisti',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Interventi",
      title: "Attività",
      icon: "mdi mdi-wrench",
      path: "interventi/interventi",
      capacita: ["menu-fasi"],
    },
    // {
    //   module: "Interventi",
    //   title: "Attività",
    //   icon: "mdi mdi-wrench",
    //   class: "has-arrow",
    //   capacita: ["menu-fasi"],
    //   submenu: [
    //     {
    //       path: "interventi/interventi",
    //       title: 'menu.interventi',
    //       icon: "mdi mdi-wrench",
    //     },
    //     {
    //       path: "interventi/gantt",
    //       title: "Gantt",
    //       icon: "mdi mdi-chart-areaspline",
    //     },
    //   ]
    // },
    {
      module: "Valutazioni",
      path: "valutazioni/check/lista",
      title: 'menu.Valutazioni',
      class: "has-arrow",
      icon: "mdi mdi-currency-eur",
      capacita: ["menu-val"],
      submenu: [
        {
          path: "valutazioni/lista",
          title: 'menu.Valutazioni',
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-val"]
        }, {
          path: "valutazioni/check/lista",
          title: 'menu.ValutazioniCheck',
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-val"],
        }
      ]
    },
    {
      module: "Documenti",
      path: "progetti/documenti",
      title: 'menu.DocumentiLabel',
      icon: "mdi mdi-file-document",
      capacita: ["menu-documenti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti/lista",
      title: 'menu.ProdottiLabel',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti/magazine/lista",
      title: 'Prodotti Magazine',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti/home/lista",
      title: 'Prodotti Home',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti/stagione/lista",
      title: 'Prodotti Stagione',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti/sconto/lista",
      title: 'Prodotti Sconti',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    {
      module: "Formazione",
      title: 'menu.FormazioneLabel',
      icon: "mdi mdi-school",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-servizi", "menu-formazione", "menu-calendar-servizi","menu-utenti-servizi"],
      submenu: [
        {
          path: "formazione/media",
          title: "menu.corsiMultimediali",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-formazione"]
        },
        {
          path: "formazione/corsi",
          title: "menu.corsi",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-calendar-servizi", "menu-servizi"]
        },
        {
          path: "/listautenti/personale/mod",
          title: "menu.docenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/tut",
          title: "menu.tutor",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/stu",
          title: "menu.studenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "formazione/materie",
          title: "menu.materie",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu_calendar_tirocini"]
        },
        {
          path: "formazione/sedi",
          title: "menu.sedi",
          icon: "mdi mdi-currency-eur",
        }
      ]
    },
    {
      module: "Prima_Nota",
      title: "menu.Prima_NotaLabel",
      icon: "mdi mdi-cart",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-spese","menu-fatture"],
      submenu: [
        {
          path: "primanota/fatture",
          title: "menu.fattVendita",
          capacita: ["menu-fatture"]
        },
        // {
        //   path: "/listautenti/aziende/f",
        //   title: "menu.fornitori",
        //   capacita: ["menu-fatture"]
        // },
        {
          path: "primanota/primanota",
          title: "menu.primanota",
          capacita: ["menu-spese"]
        },
        {
          path: "primanota/lista-spese",
          title: "menu.listaspese",
          capacita: ["menu-spese"]
        }
      ]
    },
    {
      module: "Parti",
      path: "progetti/parti",
      title: "menu.PartiLabel",
      icon: "mdi mdi-barcode",
      capacita: ["menu-parti"]
    },
    {
      module: "Parti",
      path: "progetti/parti/categorie-list",
      title: "Categorie Parti",
      icon: "mdi mdi-cart",
      capacita: ["menu-parti"]
    },
    {
      module: "Spedizioni",
      path: "spedizioni/lista",
      title: "menu.SpedizioniLabel",
      icon: "mdi mdi-airplane",
      capacita: ["menu-spedizioni"]
    },
    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampa","menu-blocchi"],
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        },
        {
          path:"/blocchi/blocchi/list",
          title: "Blocchi",
          capacita: ["menu-blocchi"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Settings",
      path: "settings/smtp",
      title: "menu.smtp",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruoli"]
    },
    {
      module: "Ticket",
      path: "ticket/list",
      title: "Tickets",
      icon: "mdi mdi-bookmark-outline",
      capacita: ["menu-ticket"]
    },
    {
      notruoliOperativi: ["CA","AC"],
      module: "Ecommerce",
      title: 'menu.EcommerceLabel',
      icon: 'mdi mdi-cart',
      class: 'has-arrow',
      submenu: [
        {
          path: '/ecom/cat',
          title: 'Products',
          icon: 'mdi mdi-cards-variant',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/cart',
          title: 'Cart',
          icon: 'mdi mdi-cart',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/orders',
          title: 'Orders',
          icon: 'mdi mdi-chart-pie',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        }
      ]
    },
    {
      ruoliOperativi:["AC","C","CA"],
      path: 'Cliente/profile',
      title: "Area Riservata",
      icon: 'mdi mdi-cart',
    }
  ],
  //#endregion
};
