import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import {HttpClient, HttpEventType, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "./Login/_services/authentication.service";
import {CampoAggiuntivo} from "./shared/models/campo-aggiuntivo";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ApiPathsService {

constructor(private authenticationService : AuthenticationService, private http: HttpClient,private toastService: ToastrService) { }
 /**
   * URL di base dell'API
   */
  private baseAPI = environment.apiUrl;
  private bbbAPI = environment.bbbUrl;

  public getBaseAPI = () => this.baseAPI;
  public setBaseAPI = (val: string) => this.baseAPI = val;
  public getBbbAPI = () => this.bbbAPI;
  public static getBaseAPI = () => environment.apiUrl;
  public options = () =>  {return {headers: this.headers()}}
  public headers = () => new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,
    });

  ClassicPost(restRoute: string ,param: any) {
    return this.http.post<any>(`${this.getBaseAPI()}${restRoute}`,
      JSON.stringify(param),
      this.options()).pipe();
  }
  ClassicGet(restRoute: string ) {
    return this.http.get<any>(`${this.getBaseAPI()}${restRoute}`,
      this.options()).pipe();
  }
  FilePost(restRoute: string ,param: any) {
    return this.http.post(`${this.getBaseAPI()}${restRoute}`,
      JSON.stringify(param),
      { headers:  new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,
        }),observe:'response',responseType:'blob'}).pipe();
  }
  FileGet(restRoute: string) {
    return this.http.get(`${this.getBaseAPI()}${restRoute}`,
      { headers:  new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,
        }),observe:'response',responseType:'blob'}).pipe();
  }
  private static Oggetto(source:any, campo: CampoAggiuntivo, oggetto:string, index: number) {
    return campo.type == 'object' ? source[oggetto][campo.field[index]] : source[oggetto];
  }
  public static DynamicFormGetter(campi: CampoAggiuntivo[], source: any, _form = new FormData()): FormData {
    campi?.forEach(campo => {
      campo.oggetti?.forEach((oggetto, index) => {
        if((campo.type != 'object' || source[oggetto]) && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray') {
          const _result = this.GetCampoOggetto(source,campo,oggetto,index);
          if(_result?.campo && _result?.oggetto)
            _form.append(_result?.campo, _result?.oggetto);
        }
        else if(campo.type == 'ArrayCampiAggiuntivi')
          this.DynamicFormGetter(campo.campiAggiuntivi, source, _form);
        else if(source[oggetto] && campo.type == 'checkboxArray')
          source[oggetto]?.filter(x => x.risposta).forEach(checkbox => {
            const send = Object.assign({}, checkbox);
            send.fields = undefined;
            send.risposta = undefined;
            send.check = undefined;
            _form.append(`${oggetto}[]`, JSON.stringify(send));
          })
      })
    })
    return _form;
  }

  private static GetCampoOggetto(source:any, campo: CampoAggiuntivo, oggetto: string, index: number): {oggetto: string, campo: string} {
    let _campo;
    let _oggetto;
    if(campo.OutputObject && campo.OutputField && this.Oggetto(source,campo,oggetto,index)) {
      _campo = campo.OutputObject;
      _oggetto = this.Oggetto(source,campo,oggetto,index)[campo.OutputField]?.toString()
    } else if (campo.OutputObject){
      _campo = campo.type == 'object' ? `${campo.OutputObject}[${campo.field[index]}]` : campo.OutputObject;
      _oggetto =  this.Oggetto(source,campo,oggetto,index)?.toString()
    }
    else if (campo.inputType == "singleDropdown" && this.Oggetto(source,campo,oggetto,index))
      Object.getOwnPropertyNames(this.Oggetto(source,campo,oggetto,index))?.forEach(prop => {
        if(this.Oggetto(source,campo,oggetto,index)[prop]) {
          _campo = campo.type == 'object' ? `${oggetto}[${campo.field[index]}][${prop}]` : `${oggetto}[${prop}]`;
          _oggetto =  this.Oggetto(source,campo,oggetto,index)[prop]?.toString()
        }
      })
    else if (campo.inputType == "multiselectDropdown" && this.Oggetto(source,campo,oggetto,index))
      Object.getOwnPropertyNames(this.Oggetto(source,campo,oggetto,index))?.forEach(prop => {
        if(this.Oggetto(source,campo,oggetto,index)[prop]) {
          _campo = campo.type == 'object' ? `${oggetto}[][${campo.field[index]}][${prop}]` : `${oggetto}[][${prop}]`;
          _oggetto =  this.Oggetto(source,campo,oggetto,index)[prop]?.toString()
        }
      })
    else if (campo.inputType == "checkbox") {
      _campo = campo.type == 'object' ? `${oggetto}[${campo.field[index]}]` : oggetto;
      _oggetto = this.Oggetto(source, campo, oggetto, index) == true ? '1' : '0';
    }
    else  {
      _campo = campo.type == 'object' ? `${oggetto}[${campo.field[index]}]` : oggetto;
      _oggetto = this.Oggetto(source,campo,oggetto,index)?.toString();
    }
    return {oggetto: _oggetto, campo: _campo}
  }

  public MultipartSendRequest(_form: FormData, link, enableUploadBtn?: boolean, uploadPercent?: number, cancel?){
    this.http.request('POST', link, {
      body: _form,
      reportProgress: true,
      observe: 'events',
      headers: {
        Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,
      },
      params: null,
    })
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            enableUploadBtn = false;
            uploadPercent = Math.round((event.loaded / event.total) * 100);
          } else if (event.type === HttpEventType.Response) {
            if (event.status === 200 || event.status === 201) {
              cancel?.emit();
            }
            else {
              enableUploadBtn = true;
              this.handleErrors(event);
            }
          }
        },
        (error) => {
          enableUploadBtn = true;
          this.handleErrors(error);
        }
      );
  }

  handleErrors(err?) {
    if(err){
      let errorMessage = err.error.message || err.statusText;
      if (err.status === 422) {
        errorMessage = "";
        for (const field in err.error) {
          errorMessage += field + " : " + err.error[field] + '\n';
        }
      }
      this.toastService.error(errorMessage, "OOPS!!");
    }
  }
}



@Injectable({
  providedIn: 'root'
})
export class HeaderService {

}

